import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Talk = () => {
  return (
    <Layout
      prev={{ name: "평론의 글", link: "/review" }}
      next={{ name: "전시 전경", link: "/overview" }}
      title={"큐레이터 토크"}
    >
      <SEO title="큐레이터 토크" />

      <div className="iframe-container">
        <iframe
          src="https://www.youtube.com/embed/baM7SaPCgik"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <br />
      <p>
        《네오-트라이브2020: 사회가 잠시 멀어졌을 때》 전시를 기획한 이도희,
        유연주, 강현웅이 이야기하는 전시의 비하인드 스토리 토크.
        네오-트라이브라는 개념에 대한 설명을 비롯한 전시 기획 의도와
        전시장으로써 기능하는 웹사이트에 대한 이야기, 네오-믹스에 대한 이야기 등
        관객이 전시에 대해 가질 법한 의문들을 질답 형식으로 풀어나간다.
      </p>
    </Layout>
  )
}

export default Talk
